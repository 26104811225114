export const state = () => ({
  userInfo: {
    name: '',
    lastName: '',
    photoURL: '',
    uid: '',
    docId: '',
    email: '',
  },
  isAuthenticated: false,
  isAdmin: false,
  reference: null,
  displayName: '',
  token: null,
  email: '',
  role: 'user',
})

export const mutations = {
  async ON_AUTH_STATE_CHANGED_MUTATION(state, { authUser }) {
    if (authUser) {
      const { uid, displayName, email, emailVerified } = authUser
      state.uid = uid
      state.displayName = displayName
      state.email = email
      state.emailVerified = emailVerified
      state.isAuthenticated = true

      let user = null

      await this.$fire.firestore
        .collection('users')
        .where('uid', '==', uid)
        .get()
        .then((querySnapshot) => {
          user = {
            ...state.userInfo,
            ...querySnapshot.docs[0].data(),
            docId: querySnapshot.docs[0].id,
          }
        })

      this.loading = false

      this.commit('user/SET_USER_INFO', {
        ...user,
      })

      this.$logRocket.identify(user.docId, {
        name: user.displayName,
        email: user.email,
        role: user.role,
      })

      this.$sentry.setUser({
        id: user.docId,
        email: user.email,
        username: user.displayName,
        role: user.role,
      })

      // dispatch getCounters
      // get email, docId and displayName from user
      const operator = {
        user: {
          id: user.docId,
          email: user.email,
          nombre: user.displayName,
        },
        role: user.role,
      }
      this.dispatch('counters/getCounters', operator, { root: true })
    } else {
      state.id = null
      state.displayName = null
      state.email = null
      state.emailVerified = false
      state.isAuthenticated = false
      state.token = null
      this.commit('user/SET_USER_INFO', {})
      this.$sentry.setUser(null)
    }
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = { ...userInfo }
  },
  SET_DISPLAYNAME(state, displayName) {
    state.displayName = displayName
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_ROLE(state, role) {
    state.role = role
  },
}

export const actions = {
  async ON_AUTH_STATE_CHANGED_ACTION({ commit, dispatch }, { authUser }) {
    if (authUser) {
      const token = await authUser.getIdToken()
      const tokenResult = await authUser.getIdTokenResult()
      commit('SET_ROLE', tokenResult.claims.role)
      commit('SET_TOKEN', token)
    }
  },
  async on_id_token_changed_action({ commit }, { authUser }) {
    if (authUser) {
      const token = await authUser.getIdToken()
      commit('SET_TOKEN', token)
    }
  },
  set_displayname({ commit }, payload) {
    commit('SET_DISPLAYNAME', payload)
  },
  async logOut() {
    await this.$fire.auth.signOut()
    await this.$router.push('/')
  },
}

export const getters = {
  userInfo(state) {
    return state.userInfo
  },
  getEmail(state) {
    return state.email
  },
  token(state) {
    return state.token
  },
  displayName(state) {
    return state.displayName
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  getRole(state) {
    return state.role
  },
}
