export const signUp = ($fire, { email, password }) => {
  return $fire.auth.createUserWithEmailAndPassword(email, password)
}

export const logIn = ($fire, { email, password }) => {
  return $fire.auth.signInWithEmailAndPassword(email, password)
}

export const logOut = ($fire) => {
  return $fire.auth.signOut()
}

export const sendPasswordReset = ($fire, email) => {
  return $fire.auth.sendPasswordResetEmail(email)
}

export const sendEmailVerification = ($fire) => {
  const currentUser = $fire.auth.currentUser
  return currentUser.sendEmailVerification()
}

export const updateProfile = ($fire, payload) => {
  const currentUser = $fire.auth.currentUser
  return currentUser.updateProfile(payload)
}

export const getUser = ($fire, id) => {
  return $fire.firestore.collection('users').doc(id).get()
}

export const getCredential = ($fireModule, body) => {
  return $fireModule.auth.EmailAuthProvider.credential(
    body.email,
    body.currentPassword
  )
}

export const reauthenticate = ($fire, credential) => {
  const currentUser = $fire.auth.currentUser
  return currentUser.reauthenticateWithCredential(credential)
}

export const updatePassword = ($fire, newPassword) => {
  const currentUser = $fire.auth.currentUser
  return currentUser.updatePassword(newPassword)
}

export const updateEmail = ($fire, newEmail) => {
  const currentUser = $fire.auth.currentUser
  return currentUser.updateEmail(newEmail)
}

export const setUserDoc = ($fire, body) => {
  return $fire.firestore.collection('users').doc().set(body)
}

export const updateUserDoc = ($fire, id, body) => {
  return $fire.firestore.collection('users').doc(id).update(body)
}

export const getUserByEmail = ($fire, email) => {
  return $fire.firestore.collection('users').where('email', '==', email).get()
}

export const deleteUser = async ($fire, uid) => {
  await $fire.firestore.collection('users').doc(uid).delete()
}
