import { format } from 'date-fns'

export const getUsers = async () => {
  let query = await this.$fire.firestore
    .collection('modules')
    .doc(this.moduleId)
    .collection(this.submoduleId)
    .orderBy('createdAt', 'desc')

  if (this.listado.state === 'init') {
    query = query.endBefore(this.lastVisible).limit(this.totalLoadItems)
  } else if (this.listado.state === 'next' && this.firstVisible) {
    query = query.startAfter(this.lastVisible).limit(this.totalLoadItems)
  }

  if (this.listado.filter.status !== 'todos') {
    query = query.where('active', '==', this.listado.filter.status)
  }

  if (this.listado.filter.inHome === true) {
    query = query.where(
      'config.showOnHomePage',
      '==',
      this.listado.filter.inHome
    )
  }

  await query
    .get()
    .then((querySnapshot) => {
      this.firstVisible = querySnapshot.docs[0]
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
      if (
        querySnapshot.docs.length < this.totalLoadItems ||
        querySnapshot.docs.length === 0
      ) {
        this.listado.showLoad = false
      } else {
        this.listado.showLoad = true
      }

      querySnapshot.forEach((doc) => {
        if (doc.id !== 'settings')
          this.posts.push({
            id: doc.id,
            path: doc.ref.path,
            status: doc.data().active,
            showOnHomePage: doc.data().config.showOnHomePage || false,
            ...doc.data(),
            createdAtTable: format(
              new Date(doc.data().createdAt.seconds * 1000),
              'dd/MM/yyyy hh:mm:ss'
            ),
          })
      })
      this.$store.dispatch('loaderFull/close')
    })
    .catch((error) => {
      this.$store.dispatch('loaderFull/close')
      this.$store.dispatch('notifications/open', {
        color: 'error',
        text: 'Ocurrió un error',
      })
      // eslint-disable-next-line no-console
      console.error('Error getting documents: ', error)
    })
}

export const getAdminUsers = async ($fire) => {
  return await $fire.firestore.collection('users')
  .where('role', '==', 'admin')
  // .where('email', 'in', ['pamela@os.city', 'pamela+operator2@os.city'])
  .get()
}
