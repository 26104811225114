import { getReportsCounters, getOperatorsCounter } from '~/services/Counters.js'

export const state = () => ({
  reportes: {
    todos: {
      total: 0,
      estado: {
        pendiente: 0,
        completado: 0,
        spam: 0,
      },
    },
    consultas: {
      total: 0,
      estado: {
        pendiente: 0,
        completado: 0,
        spam: 0,
      },
    },
    denuncias: {
      total: 0,
      estado: {
        pendiente: 0,
        completado: 0,
        spam: 0,
      },
    },
  },
  operadores: 0,

  loading: {
    reportes: true,
    operadores: true,
  },
})

export const mutations = {
  SET_COUNTERS(state, { type, value }) {
    state[type] = value
  },
  SET_SPECIFIC_LOADING(state, { type, value }) {
    state.loading[type] = value
  },
  SET_ALL_LOADING(state, value) {
    state.loading = {
      reportes: value,
      operadores: value,
    }
  },
}

export const actions = {
  async getCounters({ commit }, operator) {
    try {
      commit('SET_ALL_LOADING', true)
      const operatorsCounter = await getOperatorsCounter(this.$fire)
      if (operatorsCounter) {
        commit('SET_COUNTERS', { type: 'operadores', value: operatorsCounter })
        commit('SET_SPECIFIC_LOADING', { type: 'operadores', value: false })
      }
      const reportsCounters = await getReportsCounters(this.$fire, operator)
      if (reportsCounters) {
        commit('SET_COUNTERS', { type: 'reportes', value: reportsCounters })
        commit('SET_SPECIFIC_LOADING', { type: 'reportes', value: false })
      }
      commit('SET_ALL_LOADING', false)
    } catch (error) {
      commit('SET_ALL_LOADING', false)
      throw new Error(error)
    }
  },

  async getReportsCounters({ commit }, operator) {
    commit('SET_SPECIFIC_LOADING', { type: 'reportes', value: false })
    const reportsCounters = await getReportsCounters(this.$fire, operator)
    if (reportsCounters) {
      commit('SET_COUNTERS', { type: 'reportes', value: reportsCounters })
      commit('SET_SPECIFIC_LOADING', { type: 'reportes', value: false })
    }
  },
  async getOperatorsCounter({ commit }) {
    commit('SET_SPECIFIC_LOADING', { type: 'operadores', value: false })
    const operatorsCounter = await getOperatorsCounter(this.$fire)
    if (operatorsCounter) {
      commit('SET_COUNTERS', { type: 'operadores', value: operatorsCounter })
    }
    commit('SET_SPECIFIC_LOADING', { type: 'operadores', value: false })
  },
}
