import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51a8e3b2 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _569f4c48 = () => interopDefault(import('../pages/forms-complaints.vue' /* webpackChunkName: "pages/forms-complaints" */))
const _30275735 = () => interopDefault(import('../pages/forms-inquiries.vue' /* webpackChunkName: "pages/forms-inquiries" */))
const _a58e3e50 = () => interopDefault(import('../pages/forms-job-search.vue' /* webpackChunkName: "pages/forms-job-search" */))
const _615550b7 = () => interopDefault(import('../pages/inbox/index.vue' /* webpackChunkName: "pages/inbox/index" */))
const _71017712 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _1ecc215f = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _7c833157 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _041d494c = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _4ff79e52 = () => interopDefault(import('../pages/admin/add-operator.vue' /* webpackChunkName: "pages/admin/add-operator" */))
const _1b072786 = () => interopDefault(import('../pages/admin/operators.vue' /* webpackChunkName: "pages/admin/operators" */))
const _3ec3d642 = () => interopDefault(import('../pages/inbox/_id.vue' /* webpackChunkName: "pages/inbox/_id" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forbidden",
    component: _51a8e3b2,
    name: "forbidden"
  }, {
    path: "/forms-complaints",
    component: _569f4c48,
    name: "forms-complaints"
  }, {
    path: "/forms-inquiries",
    component: _30275735,
    name: "forms-inquiries"
  }, {
    path: "/forms-job-search",
    component: _a58e3e50,
    name: "forms-job-search"
  }, {
    path: "/inbox",
    component: _615550b7,
    name: "inbox"
  }, {
    path: "/Login",
    component: _71017712,
    name: "Login"
  }, {
    path: "/password-reset",
    component: _1ecc215f,
    name: "password-reset"
  }, {
    path: "/profile",
    component: _7c833157,
    name: "profile"
  }, {
    path: "/signup",
    component: _041d494c,
    name: "signup"
  }, {
    path: "/admin/add-operator",
    component: _4ff79e52,
    name: "admin-add-operator"
  }, {
    path: "/admin/operators",
    component: _1b072786,
    name: "admin-operators"
  }, {
    path: "/inbox/:id",
    component: _3ec3d642,
    name: "inbox-id"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
