
import { logOut } from '~/services/firebase.js'
import NotificationComponent from '~/components/Notification.vue'
import ConfirmDialog from '~/components/ConfirmDialog.vue'

export default {
  name: 'DefaultLayout',
  components: {
    NotificationComponent,
    ConfirmDialog,
  },
  data() {
    return {
      replay: null,
    }
  },
  computed: {
    authRoute() {
      return (
        this.$route.path === '/login' ||
        this.$route.path === '/signup' ||
        this.$route.path === '/password-reset'
      )
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  },
  beforeDestroy() {},
  methods: {
    async exit() {
      try {
        this.logoutLoading = true
        await logOut(this.$fire)
        this.$router.push({ path: '/login' })
        this.logoutLoading = false
      } catch (error) {
        this.logoutLoading = false
        // eslint-disable-next-line no-console
        console.error(error.message)
      }
    },
  },
}
