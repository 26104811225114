
export default {
  name: 'NotificationComponent',
  data() {
    return {
      show: false,
      color: '',
      text: '',
      timeout: -1,
      icon: '',
      title: 'Notificación',
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notifications/SHOW_NOTIFICATION') {
        this.text = state.notifications.text
        this.color = state.notifications.color
        this.timeout = state.notifications.timeout
        this.show = state.notifications.show
        if (this.color === 'success') {
          this.icon = 'mdi-checkbox-marked-circle'
        } else if (this.color === 'error') {
          this.icon = ' mdi-close-box'
        } else if (this.color === 'warning') {
          this.icon = ' mdi-alert'
        } else {
          this.icon = 'mdi-information'
        }
      } else if (mutation.type === 'notifications/CLOSE_NOTIFICATION') {
        this.show = state.notifications.show
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
}
