import { unionBy } from 'lodash'
import { getAdminUsers } from '~/services/Users'

export const state = () => ({
  adminUSer: []
})

export const mutations = {
  SET_ADMIN_USERS(state, adminUSers) {
    state.adminUSers = unionBy(state.adminUSers, adminUSers, 'email')
  },
}

export const actions = {
  async get({ commit, rootState }) {
    const data = []
    await getAdminUsers(this.$fire).then((result) => {
      if (!result.empty) {
        result.docs.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          })
        })
      }
      commit('SET_ADMIN_USERS', data)
    })
  }
}

export const getters = {
  getAdminUsers(state) {
    return state.adminUSers
  },
}