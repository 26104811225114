import _ from 'lodash'

// Revisamos primero si es 'admin', si no es, redirigimos
export default function ({ store, redirect }) {
  if (store.state.user.isAuthenticated && !isAdmin(store.state.user)) {
    return redirect('/forbidden')
  }
}

function isAdmin(user) {
  return user && user.role && _.includes(['admin'], user.role)
}
