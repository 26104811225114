
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      selectedItem: null,
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        {
          title: 'Consultas y denuncias',
          src: '/svg/ConsultasNegro.svg',
          to: '/',
          admin: false,
        },
        {
          title: 'Operadores',
          src: '/svg/Operadores.svg',
          to: '/admin/operators',
          admin: true,
        },
        {
          title: 'Buzon de entrada',
          src: '/svg/Buzon.svg',
          to: '/inbox',
          admin: false,
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js',
      menuDrown: [
        {
          title: 'Mi Perfil',
          to: '/profile',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/userInfo',
      isAuthenticated: 'user/isAuthenticated',
      role: 'user/getRole',
    }),
    isAdmin() {
      return this.role === 'admin'
    },
    displayName() {
      let name = this.$store.getters['user/displayName']
      if (name?.length > 0 && name.length > 15) {
        name = name.slice(0, 13) + '...'
      } else if (name?.length <= 15 && name.length > 0) {
        name = this.$store.getters['user/displayName']
      } else if ((name === null || name === undefined) && this.user.name) {
        name =
          this.user.name.split(' ')[0] + ' ' + this.user.lastName.split(' ')[0]
        name = name.slice(0, 13) + '...'
      } else {
        name = ''
      }

      return name
    },
    roleText() {
      let role = 'Operador'
      if (this.role === 'admin') {
        role = 'Administrador'
      }
      return role
    },
  },
  methods: {
    goToMainRN() {
      window.location.href = 'https://www.loteriaderionegro.gob.ar/'
      // window.open(
      //     'https://www.loteriaderionegro.gob.ar/',
      //     '_blank'
      //   )
    }
  }
}
