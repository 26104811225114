import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore'

export const getOperatorsCounter = async ($fire) => {
  const usersCollection = collection($fire.firestore, 'users')

  const queryOperators = query(usersCollection, where('role', '==', 'operator'))

  const operatorsCounter = await getCountFromServer(queryOperators).then(
    (querySnapshot) => querySnapshot.data().count
  )

  return operatorsCounter
}

export const getReportsCounters = async ($fire, operator) => {
  const filterQuery = ({ status = null, type = null } = {}) => {
    const reportsCollection = collection($fire.firestore, 'reports')
    const filters = []

    if (operator.role === 'admin') {
      if (type) filters.push(where('tipo', '==', type))
      if (status) filters.push(where('estado', '==', status))
    } else {
      filters.push(where('operadores', 'array-contains', operator.user))
      if (type) filters.push(where('tipo', '==', type))
      if (status) filters.push(where('estado', '==', status))
    }

    // return query
    return query(reportsCollection, ...filters)
  }

  // Todos
  const todosTotal = await getCountFromServer(filterQuery()).then(
    (querySnapshot) => querySnapshot.data().count
  )
  // Estados de reporte
  const todosPendientes = await getCountFromServer(
    filterQuery({ status: 'Pendiente' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const todosCompletados = await getCountFromServer(
    filterQuery({ status: 'Completado' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const todosSpams = await getCountFromServer(
    filterQuery({ status: 'Spam' })
  ).then((querySnapshot) => querySnapshot.data().count)

  // Consulta
  const consultasTotal = await getCountFromServer(
    filterQuery({ type: 'Consulta' })
  ).then((querySnapshot) => querySnapshot.data().count)
  // Estados de reporte
  const consultasPendientes = await getCountFromServer(
    filterQuery({ type: 'Consulta', status: 'Pendiente' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const consultasCompletadas = await getCountFromServer(
    filterQuery({ type: 'Consulta', status: 'Completado' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const consultasSpams = await getCountFromServer(
    filterQuery({ type: 'Consulta', status: 'Spam' })
  ).then((querySnapshot) => querySnapshot.data().count)

  // Denuncia
  const denunciasTotal = await getCountFromServer(
    filterQuery({ type: 'Denuncia' })
  ).then((querySnapshot) => querySnapshot.data().count)
  // Estados de reporte
  const denunciasPendientes = await getCountFromServer(
    filterQuery({ type: 'Denuncia', status: 'Pendiente' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const denunciasCompletadas = await getCountFromServer(
    filterQuery({ type: 'Denuncia', status: 'Completado' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const denunciasSpams = await getCountFromServer(
    filterQuery({ type: 'Denuncia', status: 'Spam' })
  ).then((querySnapshot) => querySnapshot.data().count)
  
  // Laboral
  const laboralTotal = await getCountFromServer(
    filterQuery({ type: 'Laboral' })
  ).then((querySnapshot) => querySnapshot.data().count)
  // Estados de reporte
  const laboralPendientes = await getCountFromServer(
    filterQuery({ type: 'Laboral', status: 'Pendiente' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const laboralCompletadas = await getCountFromServer(
    filterQuery({ type: 'Laboral', status: 'Completado' })
  ).then((querySnapshot) => querySnapshot.data().count)
  const laboralSpams = await getCountFromServer(
    filterQuery({ type: 'Laboral', status: 'Spam' })
  ).then((querySnapshot) => querySnapshot.data().count)

  const counters = {
    todos: {
      total: todosTotal,
      estado: {
        pendiente: todosPendientes,
        completado: todosCompletados,
        spam: todosSpams,
      },
    },
    consultas: {
      total: consultasTotal,
      estado: {
        pendiente: consultasPendientes,
        completado: consultasCompletadas,
        spam: consultasSpams,
      },
    },
    denuncias: {
      total: denunciasTotal,
      estado: {
        pendiente: denunciasPendientes,
        completado: denunciasCompletadas,
        spam: denunciasSpams,
      },
    },
    laboral: {
      total: laboralTotal,
      estado: {
        pendiente: laboralPendientes,
        completado: laboralCompletadas,
        spam: laboralSpams,
      },
    },
  }

  return counters
}
